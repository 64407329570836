import type { IconProps } from '../types';

export const IconMenuExchange = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52995 6.53C7.66243 6.38782 7.73455 6.19978 7.73113 6.00548C7.7277 5.81118 7.64899 5.62579 7.51157 5.48838C7.37416 5.35096 7.18877 5.27225 6.99447 5.26882C6.80017 5.2654 6.61213 5.33752 6.46995 5.47L4.46995 7.47C4.3295 7.61062 4.25061 7.80125 4.25061 8C4.25061 8.19875 4.3295 8.38937 4.46995 8.53L6.46995 10.53C6.53861 10.6037 6.62141 10.6628 6.71341 10.7038C6.80541 10.7448 6.90473 10.7668 7.00543 10.7686C7.10613 10.7704 7.20616 10.7518 7.29955 10.7141C7.39294 10.6764 7.47777 10.6203 7.54899 10.549C7.62021 10.4778 7.67635 10.393 7.71407 10.2996C7.75179 10.2062 7.77032 10.1062 7.76854 10.0055C7.76677 9.90477 7.74472 9.80546 7.70373 9.71346C7.66274 9.62146 7.60364 9.53866 7.52995 9.47L6.80995 8.75H17C17.1989 8.75 17.3896 8.67098 17.5303 8.53033C17.6709 8.38968 17.75 8.19891 17.75 8C17.75 7.80109 17.6709 7.61032 17.5303 7.46967C17.3896 7.32902 17.1989 7.25 17 7.25H6.80995L7.52995 6.53ZM16.4699 13.47C16.3295 13.6106 16.2506 13.8012 16.2506 14C16.2506 14.1987 16.3295 14.3894 16.4699 14.53L17.19 15.25H6.99995C6.80104 15.25 6.61027 15.329 6.46962 15.4697C6.32897 15.6103 6.24995 15.8011 6.24995 16C6.24995 16.1989 6.32897 16.3897 6.46962 16.5303C6.61027 16.671 6.80104 16.75 6.99995 16.75H17.19L16.4699 17.47C16.3963 17.5387 16.3372 17.6215 16.2962 17.7135C16.2552 17.8055 16.2331 17.9048 16.2314 18.0055C16.2296 18.1062 16.2481 18.2062 16.2858 18.2996C16.3235 18.393 16.3797 18.4778 16.4509 18.549C16.5221 18.6203 16.607 18.6764 16.7004 18.7141C16.7937 18.7518 16.8938 18.7704 16.9945 18.7686C17.0952 18.7668 17.1945 18.7448 17.2865 18.7038C17.3785 18.6628 17.4613 18.6037 17.53 18.53L19.53 16.53C19.6704 16.3894 19.7493 16.1987 19.7493 16C19.7493 15.8012 19.6704 15.6106 19.53 15.47L17.53 13.47C17.3893 13.3295 17.1987 13.2507 17 13.2507C16.8012 13.2507 16.6106 13.3295 16.4699 13.47Z"
        fill="currentColor"
      />
    </svg>
  );
};
