import { RefObject, useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { CommonButton } from '../../components';
import PaymentCardList from './PaymentCardList';
import PaymentBankList from './PaymentBankList';
import { Tab } from '../../types';
import { HeaderContent } from '../../layout/dashboard/header/HeaderContent';
import { Portal } from '../../components/Portal';
import { HeaderTitle } from '../../components/shared';

const ViewPayment = () => {
  const [searchParams] = useSearchParams();
  const tabId = searchParams.get('tabId');
  const [currentTab, setCurrentTab] = useState<number>(1); // Initialize currentTab here

  const tabs: Tab[] = [
    {
      id: 1,
      name: 'Bank Account',
      content: (
        <PaymentBankList
          currentTab={currentTab} // Pass currentTab as prop
        />
      ),
      path: 'bank',
    },
    {
      id: 2,
      name: 'Card',
      content: (
        <PaymentCardList
          currentTab={currentTab} // Pass currentTab as prop
        />
      ),
      path: 'card',
    },
  ];

  const matchingTab = tabs.find((tab) => tab.path === tabId);
  const parsedTabId = matchingTab ? matchingTab.id : 1;

  const handleTabClick = (id: number) => {
    setCurrentTab(id);
    localStorage.removeItem('month');
    localStorage.removeItem('year');
  };

  const { headerPortalContainerRef } = useOutletContext<{
    headerPortalContainerRef: RefObject<HTMLDivElement>;
  }>();

  useEffect(() => {
    setCurrentTab(parsedTabId);
  }, [tabId, parsedTabId]);

  return (
    <div className="relative grow overflow-y-auto">
      <Portal node={headerPortalContainerRef.current}>
        <HeaderContent>
          <HeaderTitle title="Payment Methods" isBack={true} />
        </HeaderContent>
      </Portal>
      <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
        <div className="flex mt-2">
          <CommonButton
            label={currentTab === 1 ? 'Bank List' : 'Card List'}
            className="bg-white text-primary h-[48px] px-[12px] py-[12px] rounded-lg flex items-center justify-center gap-2 font-medium dropshadow-xl cursor-auto"
          />
        </div>
      </div>
      <div className="w-full bg-white rounded-2xl mt-6 p-3 h-fit">
        <div className="grid grid-cols-2 w-full justify-center text-primary space-y-0">
          {tabs &&
            tabs.length &&
            tabs.map((tabValue, index) => (
              <div
                key={tabValue.id}
                className={`text-center ${
                  index + 1 === currentTab ? 'border-b-2 border-b-primary' : ''
                }`}
              >
                <div
                  className={`cursor-pointer my-2 text-base tracking-[-0.16px] ${
                    index + 1 === currentTab ? 'font-semibold' : 'font-normal'
                  }`}
                  onClick={() => handleTabClick(tabValue.id)}
                >
                  {tabValue.name}
                </div>
              </div>
            ))}
        </div>
      </div>
      {tabs.map((tab, i) => (
        <div key={i}>
          {currentTab === tab.id ? (
            <div>
              <p className="mt-4">{tab.content}</p>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default ViewPayment;
