import { IconProps } from './types';

export const IconPdf = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2439 1.55371L22.4169 5.90371V22.4457H6.82812V22.4997H22.4701V5.95846L18.2439 1.55371Z"
        fill="#909090"
      />
      <path d="M18.1907 1.5H6.77344V22.446H22.4154V5.90475L18.1907 1.5Z" fill="#F4F4F4" />
      <path d="M6.65969 2.625H1.86719V7.74525H16.9422V2.625H6.65969Z" fill="#7A7B7C" />
      <path d="M17.0226 7.65818H1.96484V2.53418H17.0226V7.65818Z" fill="#DD2025" />
      <path
        d="M6.95681 3.40003H5.97656V7.00003H6.74756V5.78578L6.91781 5.79553C7.08324 5.79268 7.24711 5.76305 7.40306 5.70778C7.53979 5.66075 7.66557 5.58651 7.77281 5.48953C7.88194 5.39714 7.96797 5.28055 8.02406 5.14903C8.09928 4.9304 8.12615 4.69805 8.10281 4.46803C8.09813 4.30371 8.06932 4.14097 8.01731 3.98503C7.96997 3.87246 7.89971 3.77098 7.811 3.68705C7.72229 3.60312 7.61708 3.53857 7.50206 3.49753C7.40262 3.46153 7.29987 3.4354 7.19531 3.41953C7.11613 3.40731 7.03618 3.40079 6.95606 3.40003M6.81431 5.12053H6.74756V4.01053H6.89231C6.9562 4.00592 7.0203 4.01573 7.07989 4.03922C7.13947 4.06272 7.19302 4.09931 7.23656 4.14628C7.3268 4.26704 7.37501 4.41403 7.37381 4.56478C7.37381 4.74928 7.37381 4.91653 7.20731 5.03428C7.08736 5.10025 6.95083 5.1307 6.81431 5.12053ZM9.56756 3.39028C9.48431 3.39028 9.40331 3.39628 9.34631 3.39853L9.16781 3.40303H8.58281V7.00303H9.27131C9.53443 7.01024 9.7964 6.96564 10.0423 6.87178C10.2402 6.79327 10.4155 6.66671 10.5523 6.50353C10.6853 6.33887 10.7808 6.14716 10.8321 5.94178C10.891 5.70918 10.9197 5.46996 10.9176 5.23003C10.9321 4.94665 10.9102 4.66257 10.8523 4.38478C10.7974 4.1803 10.6946 3.99185 10.5523 3.83503C10.4407 3.7084 10.3041 3.60626 10.1511 3.53503C10.0196 3.47422 9.88141 3.42939 9.73931 3.40153C9.68281 3.39219 9.62558 3.38793 9.56831 3.38878M9.43181 6.34153H9.35681V4.04353H9.36656C9.52118 4.02574 9.67762 4.05364 9.81656 4.12378C9.91831 4.20502 10.0012 4.30738 10.0596 4.42378C10.1225 4.54628 10.1588 4.68073 10.1661 4.81828C10.1728 4.98328 10.1661 5.11828 10.1661 5.23003C10.1691 5.35876 10.1608 5.4875 10.1413 5.61478C10.1182 5.74545 10.0755 5.87188 10.0146 5.98978C9.94563 6.09939 9.8525 6.19176 9.74231 6.25978C9.64978 6.31963 9.5402 6.34755 9.43031 6.33928M13.2403 3.40303H11.4178V7.00303H12.1888V5.57503H13.1638V4.90603H12.1888V4.07203H13.2388V3.40303"
        fill="#464648"
      />
      <path
        d="M16.5045 15.1911C16.5045 15.1911 18.8955 14.7576 18.8955 15.5744C18.8955 16.3911 17.4142 16.0589 16.5045 15.1911ZM14.7367 15.2534C14.3568 15.3373 13.9866 15.4602 13.632 15.6201L13.932 14.9451C14.232 14.2701 14.5432 13.3499 14.5432 13.3499C14.9012 13.9524 15.3177 14.5181 15.7867 15.0389C15.433 15.0916 15.0825 15.1637 14.7367 15.2549V15.2534ZM13.7902 10.3784C13.7902 9.66664 14.0205 9.47239 14.1997 9.47239C14.379 9.47239 14.5807 9.55864 14.5875 10.1766C14.5291 10.7981 14.399 11.4106 14.1997 12.0021C13.9268 11.5055 13.7856 10.9473 13.7895 10.3806L13.7902 10.3784ZM10.3035 18.2654C9.56996 17.8266 11.8417 16.4759 12.2535 16.4324C12.2512 16.4331 11.0715 18.7244 10.3035 18.2654ZM19.5937 15.6711C19.5862 15.5961 19.5187 14.7659 18.0412 14.8011C17.4254 14.7912 16.8098 14.8346 16.2015 14.9309C15.6122 14.3372 15.1047 13.6675 14.6925 12.9396C14.9522 12.1892 15.1093 11.4071 15.1597 10.6146C15.138 9.71464 14.9227 9.19864 14.2327 9.20614C13.5427 9.21364 13.4422 9.81739 13.533 10.7159C13.6219 11.3197 13.7895 11.9092 14.0317 12.4694C14.0317 12.4694 13.713 13.4616 13.2915 14.4486C12.87 15.4356 12.582 15.9531 12.582 15.9531C11.849 16.1917 11.159 16.5462 10.5382 17.0031C9.92021 17.5784 9.66896 18.0201 9.99446 18.4619C10.275 18.8429 11.2567 18.9291 12.1342 17.7794C12.6005 17.1855 13.0264 16.5611 13.4092 15.9104C13.4092 15.9104 14.7472 15.5436 15.1635 15.4431C15.5797 15.3426 16.083 15.2631 16.083 15.2631C16.083 15.2631 17.3047 16.4924 18.483 16.4489C19.6612 16.4054 19.6042 15.7446 19.5967 15.6726"
        fill="#DD2025"
      />
      <path d="M18.1328 1.55762V5.96237H22.3576L18.1328 1.55762Z" fill="#909090" />
      <path d="M18.1914 1.5V5.90475H22.4162L18.1914 1.5Z" fill="#F4F4F4" />
      <path
        d="M6.90017 3.34241H5.91992V6.94241H6.69392V5.72891L6.86492 5.73866C7.03035 5.73581 7.19422 5.70618 7.35017 5.65091C7.48689 5.60386 7.61267 5.52962 7.71992 5.43266C7.82823 5.34003 7.91348 5.22345 7.96892 5.09216C8.04414 4.87353 8.07101 4.64118 8.04767 4.41116C8.04299 4.24684 8.01418 4.0841 7.96217 3.92816C7.91483 3.81559 7.84456 3.71411 7.75586 3.63018C7.66715 3.54625 7.56194 3.48171 7.44692 3.44066C7.34702 3.40431 7.24377 3.37793 7.13867 3.36191C7.05949 3.34969 6.97954 3.34318 6.89942 3.34241M6.75767 5.06291H6.69092V3.95291H6.83642C6.90031 3.9483 6.96441 3.95811 7.024 3.98161C7.08358 4.0051 7.13713 4.04169 7.18067 4.08866C7.27091 4.20942 7.31912 4.35641 7.31792 4.50716C7.31792 4.69166 7.31792 4.85891 7.15142 4.97666C7.03147 5.04263 6.89494 5.07234 6.75842 5.06216M9.51092 3.33266C9.42767 3.33266 9.34667 3.33866 9.28967 3.34091L9.11342 3.34541H8.52842V6.94541H9.21692C9.48004 6.95262 9.74201 6.90803 9.98792 6.81416C10.1859 6.73565 10.3611 6.6091 10.4979 6.44591C10.6309 6.28125 10.7264 6.08954 10.7777 5.88416C10.8366 5.65156 10.8653 5.41235 10.8632 5.17241C10.8777 4.88903 10.8558 4.60495 10.7979 4.32716C10.743 4.12268 10.6402 3.93423 10.4979 3.77741C10.3863 3.65078 10.2497 3.54864 10.0967 3.47741C9.96526 3.4166 9.82702 3.37178 9.68492 3.34391C9.62841 3.33458 9.57119 3.33031 9.51392 3.33116M9.37742 6.28391H9.30242V3.98591H9.31217C9.46679 3.96812 9.62323 3.99602 9.76217 4.06616C9.86392 4.14741 9.94683 4.24976 10.0052 4.36616C10.0681 4.48866 10.1044 4.62311 10.1117 4.76066C10.1184 4.92566 10.1117 5.06066 10.1117 5.17241C10.1147 5.30114 10.1064 5.42988 10.0869 5.55716C10.0638 5.68783 10.0211 5.81426 9.96017 5.93216C9.89124 6.04178 9.79811 6.13414 9.68792 6.20216C9.59539 6.26201 9.48581 6.28993 9.37592 6.28166M13.1837 3.34541H11.3612V6.94541H12.1322V5.51741H13.1072V4.84841H12.1322V4.01441H13.1822V3.34541"
        fill="white"
      />
    </svg>
  );
};
