import clsx from 'clsx';
import { DiscountResponse, Product } from '../../../../../redux/api/retailerOrderListApi/types';
import { IconLoader } from '../../../../../assets/icons';
import { getPriceValue } from '../../../../../utils/getPriceValue';
import { Price } from '../../../../../components/shared';
import { useMemo } from 'react';

type Props = {
  initialDiscounts: Record<string, DiscountResponse>;
  isLoading?: boolean;
  className?: string;
  product: Product;
  productQuantityInCart: number;
};

export const TotalPriceWithDiscount = ({
  initialDiscounts,
  isLoading,
  className,
  product,
  productQuantityInCart,
}: Props) => {
  const currentProductDiscounts = initialDiscounts?.[product.productId];

  const discountedPrice = useMemo(() => {
    if (!currentProductDiscounts) return 0;
    return getPriceValue(currentProductDiscounts.discountedUnitPrice);
  }, [currentProductDiscounts]);

  const grossPrice = useMemo(() => {
    if (!currentProductDiscounts) return 0;
    return product.unitPrice;
  }, [currentProductDiscounts]);

  // const discountedPrice = useMemo(() => {
  //   if (!currentProductDiscounts) return 0;
  //   return (
  //     (productQuantityInCart || 1) * getPriceValue(currentProductDiscounts.discountedUnitPrice)
  //   );
  // }, [currentProductDiscounts]);
  //
  // const grossPrice = useMemo(() => {
  //   if (!currentProductDiscounts) return 0;
  //   return (productQuantityInCart || 1) * product.unitPrice;
  // }, [currentProductDiscounts]);

  const discountAmount = getPriceValue(currentProductDiscounts?.discountAmount ?? '0');

  const haveDiscount = Boolean(currentProductDiscounts && discountedPrice) && discountAmount !== 0;

  if (isLoading) {
    return (
      <div
        className={clsx('flex justify-end items-center mb-3', {
          'min-h-9': haveDiscount,
          'min-h-5': !haveDiscount,
        })}
      >
        <IconLoader className="text-grey-3 w-4 h-4" />
      </div>
    );
  }

  if (!currentProductDiscounts) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex flex-col justify-end items-end text-right min-w-[104px] mb-3',
        {
          'min-h-9': haveDiscount,
        },
        className,
      )}
    >
      <Price value={discountedPrice} className="text-subtitle-xs" />
      {haveDiscount && (
        <div className="flex gap-1">
          <Price value={grossPrice} className="text-grey-3 text-badge-sm line-through leading-4" />
          <span className="text-primary-brand text-badge-sm leading-4">
            -<Price value={grossPrice - discountedPrice} withoutCurrency />
          </span>
        </div>
      )}
    </div>
  );
};
