import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '../../assets/icons';
import { getPreviousUrl } from '../../utils/navigationTracker';
import { useMedia } from 'react-use';

type HeaderTitleProps = {
  title?: string;
  isBack?: boolean;
  navigatePath?: string;
};

export const HeaderTitle = ({ title, isBack, navigatePath }: HeaderTitleProps) => {
  const navigate = useNavigate();
  const isTablet = useMedia('(max-width:1024px)');

  if (!title) {
    return <div />;
  }

  return (
    <Button
      variant="text"
      size="medium"
      className="whitespace-nowrap"
      icon={isBack && <IconArrowLeft />}
      onClick={() => {
        if (isBack) {
          const previousUrl = getPreviousUrl();
          navigate(navigatePath || previousUrl || '/');
        }
      }}
    >
      {isTablet ? '' : title}
    </Button>
  );
};
