import { ADMIN } from '../../../../constants';
import { useGetAuthUser } from '../../../../hooks';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { headerMenuItemsHelper } from './headerMenuItemsHelper';

export const useHeaderMenu = () => {
  const { instance } = useMsal();
  const { user } = useGetAuthUser();

  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const menuItems = user?.userType ? headerMenuItemsHelper(handleLogout, user?.userType) : [];

  const name = user?.userType !== ADMIN ? user?.companyName : user?.emailId;

  return {
    isOpen,
    setIsOpen,
    menuItems,
    name,
  };
};
