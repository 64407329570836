import type { IconProps } from '../types';

export const IconPayment = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 14.5C15.5511 14.5 15.3603 14.579 15.2197 14.7197C15.079 14.8603 15 15.0511 15 15.25C15 15.4489 15.079 15.6397 15.2197 15.7803C15.3603 15.921 15.5511 16 15.75 16H18.25C18.4489 16 18.6397 15.921 18.7803 15.7803C18.921 15.6397 19 15.4489 19 15.25C19 15.0511 18.921 14.8603 18.7803 14.7197C18.6397 14.579 18.4489 14.5 18.25 14.5H15.75ZM2 8.25C2 7.38805 2.34241 6.5614 2.9519 5.9519C3.5614 5.34241 4.38805 5 5.25 5H18.75C19.1768 5 19.5994 5.08406 19.9937 5.24739C20.388 5.41072 20.7463 5.65011 21.0481 5.9519C21.3499 6.25369 21.5893 6.61197 21.7526 7.00628C21.9159 7.40059 22 7.8232 22 8.25V15.75C22 16.1768 21.9159 16.5994 21.7526 16.9937C21.5893 17.388 21.3499 17.7463 21.0481 18.0481C20.7463 18.3499 20.388 18.5893 19.9937 18.7526C19.5994 18.9159 19.1768 19 18.75 19H5.25C4.38805 19 3.5614 18.6576 2.9519 18.0481C2.34241 17.4386 2 16.612 2 15.75V8.25ZM20.5 9.5V8.25C20.5 7.78587 20.3156 7.34075 19.9874 7.01256C19.6592 6.68437 19.2141 6.5 18.75 6.5H5.25C4.78587 6.5 4.34075 6.68437 4.01256 7.01256C3.68437 7.34075 3.5 7.78587 3.5 8.25V9.5H20.5ZM3.5 11V15.75C3.5 16.716 4.284 17.5 5.25 17.5H18.75C19.2141 17.5 19.6592 17.3156 19.9874 16.9874C20.3156 16.6592 20.5 16.2141 20.5 15.75V11H3.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
