import type { IconProps } from './types';

export const IconArrowUpSmall = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13634 6.13633C4.42923 5.84344 4.9041 5.84344 5.197 6.13633L8 8.93934L10.803 6.13633C11.0959 5.84344 11.5708 5.84344 11.8637 6.13633C12.1566 6.42923 12.1566 6.9041 11.8637 7.19699L8.53033 10.5303C8.38968 10.671 8.19891 10.75 8 10.75C7.80109 10.75 7.61032 10.671 7.46967 10.5303L4.13634 7.19699C3.84344 6.9041 3.84344 6.42923 4.13634 6.13633Z"
        fill="currentColor"
      />
    </svg>
  );
};
