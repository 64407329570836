import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { CommonButton, TextField, ButtonAction } from '../../components';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router';

import { handleAlphabetPress, handleKeyDown, handleKeyPress } from '../../utils/handlers';
import {
  useAddPaymentAccountMutation,
  useUpdatePaymentAccountMutation,
} from '../../redux/api/stripeApi/stripeApi';
import { FetchQueryError } from '../../redux/api/types';
import { showToastify } from '../../utils/common';
import { bankAddValidationSchema } from './constants';
import { useOutletContext } from 'react-router-dom';
import { HeaderContent } from '../../layout/dashboard/header/HeaderContent';
import { Portal } from '../../components/Portal';
import { HeaderTitle } from '../../components/shared';

const PaymentBankAdd = () => {
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [isPrimaryError, setIsPrimaryError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const value = location?.state?.original;

  // create Project api
  const [submitBankAccount, submitBankAccountData] = useAddPaymentAccountMutation();
  const [updateBankAccount, updateBankAccountData] = useUpdatePaymentAccountMutation();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPrimary(event.target.checked);
  };

  const handleSubmit = async (values: {
    accountNumber: string;
    accountName: string;
    routingNumber: string;
  }) => {
    setIsLoading(true);
    if (value) {
      if (!value?.isPrimary && isPrimary)
        updateBankAccount({
          accountId: value.id,
        });
      else {
        setIsPrimaryError(true);
      }
    } else if (Object.keys(formik.errors).length === 0) {
      try {
        const formData = new URLSearchParams();
        formData.append('bank_account[country]', 'US');
        formData.append('bank_account[currency]', 'usd');
        formData.append('bank_account[account_number]', values.accountNumber);
        formData.append('bank_account[account_holder_name]', values.accountName);
        formData.append('bank_account[routing_number]', values.routingNumber);
        formData.append('bank_account[account_holder_type]', 'individual');

        const response = await fetch('https://api.stripe.com/v1/tokens', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData.toString(),
        });

        const data = await response.json();

        if (!response.ok) {
          toast.error('Invalid account');
          setIsLoading(false);
          throw new Error(data.error.message || 'Error submitting bank data');
        }

        // toast.success('Account added succesfully')
        const datas = {
          accountType: 'bank_account',
          accountName: values?.accountName,
          last4: values.accountNumber.substr(-4),
          routingNumber: values?.routingNumber.toString(),
          isPrimary: isPrimary,
          stripeToken: data?.id,
        };
        submitBankAccount(datas);
      } catch (error) {
        // Handle errors, e.g., display error message to the user
        console.error('Error submitting bank data:');
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountNumber: '',
      accountName: '',
      routingNumber: '',
    },
    validationSchema: bankAddValidationSchema(value),
    onSubmit: handleSubmit,
  });

  const { headerPortalContainerRef } = useOutletContext<{
    headerPortalContainerRef: RefObject<HTMLDivElement>;
  }>();

  useEffect(() => {
    if (value) {
      formik.setFieldValue('accountNumber', '************' + value?.last4);
      formik.setFieldValue('accountName', value?.accountName);
      formik.setFieldValue('routingNumber', value?.routingNumber);
      setIsPrimary(value?.isPrimary);
    }
  }, [value]);

  useEffect(() => {
    if (updateBankAccountData?.isSuccess) {
      setIsLoading(false);
      navigate('/payment?tabId=bank');

      showToastify('success', updateBankAccountData?.data?.message);
    }
  }, [updateBankAccountData]);
  useEffect(() => {
    if (submitBankAccountData?.isSuccess) {
      setIsLoading(false);
      navigate('/payment?tabId=bank');
      showToastify('success', submitBankAccountData?.data?.message);
    } else if (submitBankAccountData?.isError) {
      setIsLoading(false);
      showToastify('error', (submitBankAccountData?.error as FetchQueryError)?.data?.error);
    }
  }, [submitBankAccountData]);

  return (
    <div className="relative grow overflow-y-auto">
      <Portal node={headerPortalContainerRef.current}>
        <HeaderContent>
          <HeaderTitle title={value ? 'Update Bank' : 'Add Bank'} />
        </HeaderContent>
      </Portal>
      <div className="flex mt-2">
        <CommonButton
          label={'Bank Account'}
          className="bg-white text-primary px-[10px] py-[12px] flex justify-between items-center gap-3 dropshadow-xl"
          icon={<Icon icon="ic:round-arrow-back" className="text-primary" width="20" height="20" />}
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>

      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="w-full bg-white rounded-2xl my-6 p-6 h-fit sm:mb-32">
            <div className="grid xl:grid-cols-3 md:grid-cols-2 xl:gap-x-12 md:gap-x-6 sm:gap-y-6 gap-x-3 gap-y-8">
              <div>
                {value ? (
                  <>
                    <div className="flex items-center">
                      <label className="block  mb-2 text-sm font-semibold text-secondary">
                        Account Number
                      </label>
                      <span className="text-secondary ms-1">*</span>
                    </div>
                    <div className="relative text-left cursor-not-allowed">
                      {/* <span className="absolute left-4 top-3 text-2xl opacity-30">
                              ************
                            </span>
                            <span className="absolute left-32 top-1/2 transform -translate-y-1/2 opacity-30 ms-1">
                              {value?.last4}
                            </span> */}
                      <input
                        type="text"
                        value={'************' + (value?.last4 || '')}
                        readOnly
                        className="pl-20 border border-gray-100 rounded-lg block w-full p-3 text-sm cursor-not-allowed disabled"
                      />
                    </div>
                  </>
                ) : (
                  <TextField
                    label="Account Number"
                    fieldName="accountNumber"
                    required={true}
                    type="text"
                    maxLength={12}
                    placeholder="Enter account number"
                    disabled={value}
                    props={{
                      handleChange: formik.handleChange,
                      handleBlur: formik.handleBlur,
                      values: formik.values,
                      errors: formik.errors as { [key: string]: string },
                      touched: formik.touched as { [key: string]: boolean },
                    }}
                    value={formik.values.accountNumber}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e, 13)}
                    onKeyDown={handleKeyDown}
                    onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData.getData('text');
                      const sanitizedText = pastedText.replace(/[^\d]/g, ''); // Remove non-numeric characters
                      const maxLength = 12;
                      const currentLength = formik.values.accountNumber.length;
                      const remainingLength = maxLength - currentLength;
                      const newTextLength = Math.min(sanitizedText.length, remainingLength);
                      const newValue =
                        formik.values.accountNumber.slice(0, currentLength) +
                        sanitizedText.slice(0, newTextLength);

                      // Update formik value with sanitized pasted text
                      formik.setFieldValue('accountNumber', newValue);
                    }}
                  />
                )}
              </div>
              <div>
                <TextField
                  label="Account Name"
                  fieldName="accountName"
                  required={true}
                  type="text"
                  maxLength={24}
                  placeholder="Enter account name"
                  disabled={value}
                  props={{
                    handleChange: formik.handleChange,
                    handleBlur: formik.handleBlur,
                    values: formik.values,
                    errors: formik.errors as { [key: string]: string },
                    touched: formik.touched as { [key: string]: boolean },
                  }}
                  value={formik.values.accountName}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleAlphabetPress(e, 25)
                  }
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const maxLength = 24;
                    const currentValue = formik.values.accountName;
                    const remainingLength = maxLength - currentValue.length;
                    if (remainingLength > 0) {
                      e.preventDefault(); // Prevent default pasting behavior
                      // Get pasted text
                      const pastedText = e.clipboardData.getData('text');
                      // Truncate if pasted text exceeds maxLength
                      const truncatedText = pastedText.slice(0, remainingLength);
                      // Remove numbers from pasted text
                      const filteredText = truncatedText.replace(/[^\p{L}\s]/gu, '');
                      // Insert filtered text at cursor position
                      const input = e.target as HTMLInputElement;
                      const cursorPosition: number | null = input.selectionStart;
                      const currentValue: string = input.value;
                      const newValue =
                        currentValue.slice(0, cursorPosition ?? 0) +
                        filteredText +
                        currentValue.slice(input.selectionEnd ?? 0);
                      input.value = newValue;
                      // Update formik value
                      formik.setFieldValue('accountName', newValue);
                    }
                  }}
                />
              </div>
              <div>
                <TextField
                  label="Routing Number"
                  fieldName="routingNumber"
                  required={true}
                  type="text"
                  maxLength={9}
                  placeholder="Enter routing number"
                  disabled={value}
                  props={{
                    handleChange: formik.handleChange,
                    handleBlur: formik.handleBlur,
                    values: formik.values,
                    errors: formik.errors as { [key: string]: string },
                    touched: formik.touched as { [key: string]: boolean },
                  }}
                  value={formik.values.routingNumber}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e, 10)}
                  onKeyDown={handleKeyDown}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData.getData('text');
                    const sanitizedText = pastedText.replace(/[^\d]/g, ''); // Remove non-numeric characters
                    const maxLength = 9;
                    const currentLength = formik.values.routingNumber.length;
                    const remainingLength = maxLength - currentLength;
                    const newTextLength = Math.min(sanitizedText.length, remainingLength);
                    const newValue =
                      formik.values.routingNumber.slice(0, currentLength) +
                      sanitizedText.slice(0, newTextLength);

                    // Update formik value with sanitized pasted text
                    formik.setFieldValue('routingNumber', newValue);
                  }}
                  // onPaste={(e) => {
                  //   e.preventDefault()
                  //   const pastedText = e.clipboardData.getData('text')
                  //   const sanitizedText = pastedText.replace(/[^\d]/g, '') // Remove non-numeric characters
                  //   const maxLength = 9
                  //   const currentLength =
                  //     formik.values.accountNumber.length
                  //   const remainingLength = maxLength - currentLength
                  //   const newTextLength = Math.min(
                  //     sanitizedText.length,
                  //     remainingLength,
                  //   )
                  //   const newValue =
                  //     formik.values.accountNumber.slice(
                  //       0,
                  //       currentLength,
                  //     ) + sanitizedText.slice(0, newTextLength)

                  //   // Update formik value with sanitized pasted text
                  //   formik.setFieldValue('routingNumber', newValue)
                  // }}
                />
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="isPrimary"
                name="isPrimary"
                className="custom-checkbox cursor-pointer mt-8 w-[17px] h-[17px]"
                checked={isPrimary}
                disabled={value?.isPrimary}
                onChange={handleCheckboxChange}
              />
              <div className="mt-8 ms-2 cursor-pointer" onClick={() => setIsPrimary(!isPrimary)}>
                <label className="block text-sm font-semibold text-secondary cursor-pointer">
                  Make as Primary
                </label>
              </div>
            </div>
            {!value?.isPrimary && !isPrimary && isPrimaryError && (
              <p className="error mt-2 text-sm">Please select as primary</p>
            )}
            {value && value?.isPrimary ? (
              ''
            ) : (
              <div className="mt-8 flex justify-end">
                <ButtonAction
                  label={value ? 'Update' : 'Submit'}
                  disabled={value?.isPrimary || isLoading || (value && !isPrimary)}
                  className={`w-[95px] h-[42px] bg-primary font-medium rounded-lg text-white ${value && !isPrimary ? 'disabled' : ''}`}
                  props={{
                    ...formik,
                  }}
                  spinColor="white"
                  spinHeight="20"
                  isLoading={isLoading}
                  spinWidth="20"
                />
              </div>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default PaymentBankAdd;
