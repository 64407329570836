import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { captureException } from '@sentry/react';
import { msalInstance } from '../../App';
import { showToastify } from '../../utils/common';
import { MutationExtraType } from '../../types';
import { IdTokenClaims } from './types';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    try {
      const token = localStorage.getItem('ACCESS_TOKEN');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
    } catch (error) {
      console.error('Error parsing token from localStorage:', error);
    }
    return headers;
  },
});

function reftoken() {
  const getLocalStorageKeys = Object.keys(localStorage);
  const getBaseAuthIdToken = getKeyValuesByLocalStorage(/refreshtoken/gi, getLocalStorageKeys);

  if (getBaseAuthIdToken) {
    const getlocalStorageIdToken = JSON.parse(localStorage.getItem(getBaseAuthIdToken) || '');

    localStorage.setItem('REFRESH_TOKEN', getlocalStorageIdToken?.secret);
  } else {
    console.error('Base auth id token not found in local storage.');
  }
}

function getKeyValuesByLocalStorage(pattern: RegExp, getLocalStorageKeys: string[]) {
  let localStorageKey = '';
  getLocalStorageKeys &&
    getLocalStorageKeys.length &&
    getLocalStorageKeys.forEach((values) => {
      if (values.match(pattern)) {
        if (values.match(pattern)) {
          localStorageKey = values;
        }
      }
    });
  return localStorageKey;
}

// Define a flag to track whether the token has been acquired
let tokenAcquired = false;

const baseQueryWithTokenRefresh = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: MutationExtraType,
) => {
  try {
    const account = msalInstance.getAllAccounts()[0];
    if (!tokenAcquired) {
      // Ensure you have a valid account object before calling acquireTokenSilent
      if (!account) {
        throw new Error('No active account available.');
      }

      await msalInstance
        .acquireTokenSilent({
          scopes: [],
          account: account,
          authority: process.env.REACT_APP_AAD_AUTHORITIES_SIGNUP_SIGNIN,
          redirectUri: process.env.REACT_APP_AZURE_REDIRECTURI,
        })
        .then((accessTokenResponse) => {
          const accessToken = accessTokenResponse.idToken;

          // Specify the type of accessTokenResponse
          const typedAccessTokenResponse = accessTokenResponse.idTokenClaims as IdTokenClaims;

          // Check if the 'name' claim exists before accessing it
          if (typedAccessTokenResponse?.name) {
            localStorage.setItem('ACCESS_TOKEN_RESPONSE', typedAccessTokenResponse.name);
          } else {
            // Handle the case where 'name' claim is missing or undefined
            // You can set a default value or handle it as needed
            console.warn('Name claim is missing in the access token.');
          }
          // Check if the 'emails' claim exists before accessing it
          if (typedAccessTokenResponse?.oid) {
            localStorage.setItem('OID', typedAccessTokenResponse?.oid);
          }
          if (typedAccessTokenResponse?.emails && typedAccessTokenResponse?.emails?.length > 0) {
            localStorage.setItem('LOGGED_IN_USER', typedAccessTokenResponse.emails[0]);
          } else {
            console.warn('Emails claim is missing in the access token.');
          }
          localStorage.setItem('ACCESS_TOKEN', accessToken);

          // Set the tokenAcquired flag to true after acquiring the token
          tokenAcquired = true;
        });
    }

    const result = await baseQuery(args, api, extraOptions);

    if (result.meta?.response?.status === 200) {
      const contentType = result.meta.response.headers.get('content-type');
      if (contentType && !contentType.includes('application/json')) {
        result.data = { data: result.error };
        const tempData = result.error;
        delete result.error;
        return { ...result, data: tempData };
      }
    }
    return result;
  } catch (error) {
    if ((error as Error).name === 'InteractionRequiredAuthError') {
      localStorage.clear();
      window.location.href = '/';
    } else {
      // For other errors, re-throw the error after handling
      throw error;
    }
  }
};

const customFetchBase = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: MutationExtraType,
) => {
  await mutex.waitForUnlock();

  const result = await baseQuery(args, api, extraOptions);

  const token = localStorage.getItem('ACCESS_TOKEN');

  const currentRoute = window.location.pathname;
  const route =
    currentRoute === '/distributor-onboard' ||
    currentRoute === '/retailer-onboard' ||
    currentRoute === '/passwordsetup';
  if (!token && !route) {
    localStorage.clear();
    window.location.reload();
  }

  if (result.error) {
    if (result.error.status === 401) {
      await baseQueryWithTokenRefresh(args, api, extraOptions);
      window.location.reload();
      reftoken();
    } else {
      captureException(result.error);

      if (result.error.status === 403) {
        showToastify('error', 'Forbidden');
      }
    }
  }

  return result;
};

export default customFetchBase;
