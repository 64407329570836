import type { IconProps } from '../types';

export const IconProfile = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62179 14.6795C8.05906 14.6795 7.51937 14.903 7.12146 15.3009C6.72355 15.6988 6.5 16.2385 6.5 16.8013C6.5 16.9832 6.57226 17.1576 6.70089 17.2863C6.82953 17.4149 7.00399 17.4872 7.1859 17.4872H15.8013C15.9832 17.4872 16.1577 17.4149 16.2863 17.2863C16.4149 17.1576 16.4872 16.9832 16.4872 16.8013C16.4872 16.2385 16.2636 15.6988 15.8657 15.3009C15.4678 14.903 14.9281 14.6795 14.3654 14.6795H8.62179ZM6.0608 14.2403C6.74002 13.5611 7.66124 13.1795 8.62179 13.1795H14.3654C15.3259 13.1795 16.2472 13.5611 16.9264 14.2403C17.6056 14.9195 17.9872 15.8407 17.9872 16.8013C17.9872 17.381 17.7569 17.937 17.3469 18.3469C16.937 18.7569 16.381 18.9872 15.8013 18.9872H7.1859C6.60616 18.9872 6.05017 18.7569 5.64023 18.3469C5.2303 17.937 5 17.381 5 16.8013C5 15.8407 5.38158 14.9195 6.0608 14.2403Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4936 7.5C10.7183 7.5 10.0898 8.12852 10.0898 8.90385C10.0898 9.67917 10.7183 10.3077 11.4936 10.3077C12.2689 10.3077 12.8974 9.67917 12.8974 8.90385C12.8974 8.12852 12.2689 7.5 11.4936 7.5ZM8.58975 8.90385C8.58975 7.3001 9.88985 6 11.4936 6C13.0973 6 14.3974 7.3001 14.3974 8.90385C14.3974 10.5076 13.0973 11.8077 11.4936 11.8077C9.88985 11.8077 8.58975 10.5076 8.58975 8.90385Z"
        fill="currentColor"
      />
    </svg>
  );
};
