import { useEffect, useState } from 'react';

import { LegacyTable, MarkupModal } from '../../components';

import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import {
  useDeletePaymentAccountMutation,
  useGetUserAccountMutation,
} from '../../redux/api/stripeApi/stripeApi';
import { MutationExtraType, CellType } from '../../types';
import { FetchQueryError } from '../../redux/api/types';
import { showToastify } from '../../utils/common';

type TableRow = {
  status?: number;
  joinedDate?: string;
  companyName?: string;
  isPrimary?: boolean;
  isVerified?: boolean;
  id?: string;
  accountType?: string;
  accountName?: string;
  last4?: string;
};

const PaymentCardList = ({ currentTab }: { currentTab: number }) => {
  const navigate = useNavigate();
  const [mutationExtra, setMutationExtra] = useState<MutationExtraType>({
    sortBy: 'createdAt:DESC',
  });
  const [deleteCardApi, DeleteCardApiData] = useDeletePaymentAccountMutation();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [accountId, setAccountid] = useState<TableRow>({});
  const handleConfirmModal = (original: TableRow) => {
    setConfirmModal(true);
    setAccountid(original);
  };
  const handleDeleteApiCall = () => {
    deleteCardApi({ accountId: accountId.id });
  };

  const columns = [
    {
      header: 'Account Type',
      accessorKey: 'accountType',
      canSort: false,
      sortFieldName: 'accountType',
      cell: (value: CellType<TableRow>) => {
        return (
          <>
            <span className="mx-3">{value.row.original.accountType}</span>
            {value.row.original.isPrimary && (
              <button className="ml-3 bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold cursor-auto">
                Primary
              </button>
            )}
          </>
        );
      },
    },
    {
      header: 'Card Holder Name',
      accessorKey: 'accountName',
      canSort: false,
      sortFieldName: 'accountName',
    },
    {
      header: 'Card Number',
      accessorKey: 'last4',
      canSort: false,
      sortFieldName: 'last4',
      cell: (cell: CellType<TableRow>) =>
        cell.row.original?.last4 ? (
          <span>
            <sub className="text-lg">**** **** **** </sub>
            {`${cell.row.original?.last4}`}
          </span>
        ) : (
          ''
        ),
    },
    // {
    //   header: 'Verified',
    //   accessorKey: 'isVerified',
    //   canSort: false,
    //   sortFieldName: 'isVerified',
    //   cell: (value: CellType<TableRow>) => {
    //     // Check the Status value and conditionally render a button
    //     if (value.row.original.isVerified) {
    //       return (
    //         <button className="bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold cursor-auto">
    //           Verified
    //         </button>
    //       )
    //     } else {
    //       return (
    //         <span
    //         className={`bg-white px-3 py-[4px] text-primary rounded-lg font-medium cursor-pointer ${value.row.original?.isVerified ? 'disabled' : ''}`}
    //         onClick={() => {
    //               setVerifyModal(true)
    //               setAccountid(value.row.original.id)
    //               setCardVerify({
    //                 firstDeposit: '',
    //                 secondDeposit: '',
    //                 firstDepositError: '',
    //                 secondDepositError: '',
    //               })
    //             }}
    //       >
    //         Verify Now
    //       </span>
    //       )
    //     }
    //   },
    // },
    // {
    //   header: '',
    //   accessorKey: 'isPrimary',
    //   canSort: false,
    //   sortFieldName: 'isPrimary',
    //   cell: (value: CellType<TableRow>) => {
    //     if (value.row.original.isPrimary) {
    //       return (
    //         <button className="bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold cursor-auto">
    //           Primary
    //         </button>
    //       )
    //     }
    //   },
    // },
    {
      header: 'Action',
      accessorKey: 'status',
      canSort: false,
      cell: ({
        cell: {
          row: { original },
        },
      }: {
        cell: CellType<TableRow>;
      }) => (
        <div className="flex flex-row gap-2">
          <div
            className="cursor-pointer"
            onClick={() => navigate('/payment/card-add', { state: { original } })}
          >
            <Icon icon="ph:eye-bold" color="#374BFF" width="18" height="18" />{' '}
          </div>
          <div className="cursor-pointer" onClick={() => handleConfirmModal(original)}>
            <Icon icon="ph:trash-bold" color="#B20036" width="18" height="18" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (DeleteCardApiData?.isSuccess) {
      showToastify('success', DeleteCardApiData?.data?.message);
      setMutationExtra({
        sortBy: 'createdAt:DESC',
      });
      setConfirmModal(false);
    } else if (DeleteCardApiData?.isError) {
      showToastify('error', (DeleteCardApiData?.error as FetchQueryError)?.data?.error);
    }
  }, [DeleteCardApiData]);

  return (
    <div className="relative grow overflow-y-auto">
      <div className="relative">
        <LegacyTable
          dataColumns={columns}
          payment="bank_account"
          addTitle="MappedUser"
          isSortable={true}
          canAdd={true}
          defaultSort={'createdAt'}
          AddPath={currentTab == 1 ? '/payment/bank-add' : '/payment/card-add'}
          useMutation={useGetUserAccountMutation}
          mutationExtra={mutationExtra}
          noData={
            <>
              No payment information was found.
              <br />
              Please ensure that you have added at least one payment method, either a credit card or
              a bank account, to enable automatic processing of all your invoices.
            </>
          }
        />
        <MarkupModal
          showModal={confirmModal}
          title="Confirmation"
          body={
            <>
              <p className="mt-3">
                Are you sure you want to delete the {accountId?.accountName} card ?
              </p>
            </>
          }
          btn2Value={'Confirm'}
          loading={DeleteCardApiData?.isLoading}
          btn1Value={'Cancel'}
          btn2Click={() => {
            handleDeleteApiCall();
          }}
          btn1Click={() => {
            setConfirmModal(false);
          }}
          close={() => {
            setConfirmModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default PaymentCardList;
