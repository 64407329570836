import { useEffect } from 'react';
import '../../styles/tempStyles.css';
import { useGetMetricsOnSalesMutation } from '../../redux/api/dashboardApi/dashboardApi';
import moment from 'moment';
import { RetailerTopSoldProducts } from './components/RetailerTopSoldProducts';
import { RetailerOrderedProducts } from './components/RetailerOrderedProducts';
import { RetailerSales } from './components/RetailerSales';
import { Scrollbars } from 'rc-scrollbars';

const Retailer = () => {
  const [getMetrics, { data, isLoading }] = useGetMetricsOnSalesMutation();
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();

  useEffect(() => {
    getMetrics(offset);
  }, [getMetrics]);

  return (
    <Scrollbars autoHide>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6">
          <RetailerSales isLoading={isLoading} data={data?.data} />
          <RetailerTopSoldProducts />
        </div>
        <div className="col-span-12 lg:col-span-6 mb-12">
          <RetailerOrderedProducts />
        </div>
      </div>
    </Scrollbars>
  );
};

export default Retailer;
