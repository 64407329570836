type PaginationInfoProps = {
  totalCount: number;
  currentPage: number;
  pageItemsCount: number;
};

export const PaginationInfo = ({
  totalCount,
  currentPage,
  pageItemsCount,
}: PaginationInfoProps) => {
  const firstEntryIndex = pageItemsCount * (currentPage - 1) + 1;
  const lastEntryIndex =
    totalCount && pageItemsCount * currentPage >= totalCount
      ? totalCount
      : pageItemsCount * currentPage;
  const showingEntriesInfo = `Showing ${firstEntryIndex} to ${lastEntryIndex} of ${totalCount} entries`;

  return (
    <span className="flex gap-1 items-center text-body-md text-grey-1">{showingEntriesInfo}</span>
  );
};
