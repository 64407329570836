import { RETAILER, DISTRIBUTOR } from '../../../../constants';
import { adminMenuItems, distributorMenuItems, retailerMenuItems } from './constants';

const getMenuItems = (userType: string) => {
  switch (userType) {
    case DISTRIBUTOR:
      return distributorMenuItems;
    case RETAILER:
      return retailerMenuItems;
    default:
      return adminMenuItems;
  }
};

export const headerMenuItemsHelper = (logout: () => void, userType: string) => {
  const menuItems = getMenuItems(userType);

  return menuItems.map((menuItem) => {
    if (menuItem.id === 'logOut') {
      menuItem.onClick = logout;

      return menuItem;
    }

    return menuItem;
  });
};
