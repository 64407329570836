import { Icon } from '@iconify/react';
import '../../../styles/tempStyles.css';
import { SidebarProps } from '../../../types';
import React from 'react';
import { IconLogo } from '../../../assets/icons';
import clsx from 'clsx';

export const SidebarToggler = ({ isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
  const toggleSidebar = () => {
    setIsSidebarOpen?.(!isSidebarOpen);
  };

  return (
    <div
      className={clsx(
        'inline-flex items-center justify-between w-full p-3 text-sm text-gray-500 rounded-lg lg:hidden',
        { hidden: isSidebarOpen },
      )}
      onClick={toggleSidebar}
    >
      <IconLogo />
      <Icon icon="ri:menu-3-line" className="text-primary" width="24" height="24" />
    </div>
  );
};
