import { flexRender } from '@tanstack/react-table';
import type { HeaderContext } from '@tanstack/react-table';
import { getCommonPinningStyles, getPinningClassName } from '../utils/getCommonPinningStyles';
import type { CustomColumnDef, TableDefinition } from '../types';
import clsx from 'clsx';
import { IconArrowUpSmall, IconSortable } from '../../../assets/icons';
import { Button } from '../../Button';

export const TableHeaderGroups = <T extends object>({ table }: TableDefinition<T>) => {
  return (
    <thead className="border-b border-grey-6">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const column = header.column.columnDef as CustomColumnDef<T>;

            if (column.isHidden) {
              return null;
            }

            return (
              <th
                key={header.id}
                className={clsx(
                  'text-grey-3 p-3 border-b border-grey-6 whitespace-nowrap',
                  getPinningClassName(header.column),
                  typeof column.headerContainerClassName === 'function'
                    ? column.headerContainerClassName(header.getContext())
                    : column.headerContainerClassName,
                )}
                style={{ ...getCommonPinningStyles(header.column, column.piningPosition) }}
              >
                <div
                  className={clsx(
                    'flex flex-nowrap items-center text-xs font-medium w-full h-full group',
                    {
                      'cursor-pointer': column.enableSorting,
                    },
                    typeof column.headerClassName === 'function'
                      ? column.headerClassName(header.getContext())
                      : column.headerClassName,
                  )}
                  {...(column.enableSorting
                    ? {
                        onClick: () => {
                          column.sortParams?.onSort?.();
                        },
                      }
                    : {})}
                >
                  {flexRender(
                    column.header,
                    header.getContext() as HeaderContext<T, string | number>,
                  )}

                  {column.enableSorting && (
                    <Button
                      className={clsx({
                        'opacity-100': column.sortParams.isSorted,
                        'opacity-30': !column.sortParams.isSorted,
                      })}
                      icon={
                        column.sortParams.isSorted ? (
                          <IconArrowUpSmall
                            className={clsx(
                              'w-4 h-4 transition duration-200 text-grey-1 group-hover:text-primary-brand-dark active:text-grey-1 ',
                              {
                                'transform rotate-180':
                                  column.sortParams.isSorted &&
                                  column.sortParams.sortingOrder === 'desc',
                                'transform rotate-0':
                                  !column.sortParams.isSorted ||
                                  column.sortParams.sortingOrder === 'asc',
                              },
                            )}
                          />
                        ) : (
                          <IconSortable className="transition duration-200 text-grey-1 group-hover:text-primary-brand-dark" />
                        )
                      }
                      variant="grey"
                      size="small"
                    />
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
