import { IconProps } from './types';

export const IconSortable = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46967 2.81967C4.76256 2.52678 5.23744 2.52678 5.53033 2.81967L8 5.28934L10.4697 2.81967C10.7626 2.52678 11.2374 2.52678 11.5303 2.81967C11.8232 3.11256 11.8232 3.58744 11.5303 3.88033L8.67175 6.73891C8.30075 7.1099 7.69925 7.10991 7.32825 6.73891L4.46967 3.88033C4.17678 3.58744 4.17678 3.11256 4.46967 2.81967Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5303 13.2146C11.2374 13.5075 10.7626 13.5075 10.4697 13.2146L8 10.745L5.53033 13.2146C5.23744 13.5075 4.76256 13.5075 4.46967 13.2146C4.17678 12.9217 4.17678 12.4469 4.46967 12.154L7.32825 9.29539C7.69925 8.9244 8.30075 8.92439 8.67175 9.29539L11.5303 12.154C11.8232 12.4469 11.8232 12.9217 11.5303 13.2146Z"
        fill="currentColor"
      />
    </svg>
  );
};
