import { RefObject, useEffect, useState } from 'react';
import { useLoginUserMutation } from '../../redux/api/authApi/authApi';
import Distributer from './Distrubuter';
import Retailer from './Retailer';
import Admin from './Admin';
import { LoadingScreen } from '../../components';
import { useIdToken } from '../../hooks';
import { toast } from 'react-toastify';
import { ADMIN, DISTRIBUTOR, RETAILER } from '../../constants';
import { FetchQueryError } from '../../redux/api/types';
import { HeaderContent } from '../../layout/dashboard/header/HeaderContent';
import { Portal } from '../../components/Portal';
import { useOutletContext } from 'react-router-dom';
import { HeaderTitle } from '../../components/shared';

const Dashboard = () => {
  const [loginUser, { data: loginData, isError, isLoading, error }] = useLoginUserMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const { idToken } = useIdToken();

  useEffect(() => {
    const fetchData = async () => {
      if (idToken) {
        await loginUser();
      }
      setLoading(false);
    };

    fetchData();
  }, [idToken]);

  useEffect(() => {
    if (isError) {
      toast.error((error as FetchQueryError)?.data?.message);
    }
  }, [isLoading]);

  useEffect(() => {
    localStorage.setItem('TYPE', loginData?.data?.userType || '');
  }, [loginData]);

  const renderContent = () => {
    if (loading) {
      return <LoadingScreen />;
    }

    if (loginData && loginData?.data?.userType === DISTRIBUTOR) {
      return <Distributer />;
    }

    if (loginData && loginData?.data?.userType === RETAILER) {
      return <Retailer />;
    }

    if (loginData && loginData?.data?.userType === ADMIN) {
      return <Admin />;
    }

    return <p>User type not recognized.</p>;
  };

  const { headerPortalContainerRef } = useOutletContext<{
    headerPortalContainerRef: RefObject<HTMLDivElement>;
  }>();

  return (
    <>
      <Portal node={headerPortalContainerRef.current}>
        <HeaderContent>
          <HeaderTitle title="Dashboard" />
        </HeaderContent>
      </Portal>
      {renderContent()}
    </>
  );
};

export default Dashboard;
