import { PaginationCountPerPage, PaginationInfo, PaginationPages } from './components/';

type PaginationProps = {
  totalCount: number;
  onPageChange: (pageNumber: number) => void;
  isLoading: boolean;
  currentPage: number;
  pageItemsCount: number;
  onPageItemsCountChange: (count: number) => void;
};

export const Pagination = ({
  totalCount,
  onPageChange,
  isLoading = false,
  currentPage,
  pageItemsCount,
  onPageItemsCountChange,
}: PaginationProps) => {
  return (
    <div className="flex justify-between p-3 items-center mt-2 gap-4">
      <PaginationCountPerPage
        isLoading={isLoading}
        pageItemsCount={pageItemsCount}
        onPageItemsCountChange={onPageItemsCountChange}
      />
      <div className="flex items-center gap-4">
        <PaginationInfo
          totalCount={totalCount}
          currentPage={currentPage}
          pageItemsCount={pageItemsCount}
        />
        <PaginationPages
          totalCount={totalCount}
          pageItemsCount={pageItemsCount}
          currentPage={currentPage}
          onPageChange={onPageChange}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
