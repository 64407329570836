import { IconAdd, IconMinus } from '../../assets/icons';
import { Button } from '../Button';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { removeLeadingZeros } from '../../utils/removeLeadingZeros';

export type CounterProps = {
  currentCount: number;
  onCountChange: (count: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  className?: string;
};

export const Counter = ({
  currentCount,
  onCountChange,
  max,
  min,
  disabled,
  className,
}: CounterProps) => {
  const [count, setCount] = useState(currentCount);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCountApply = (updatedCount: number) => {
    if (min !== undefined && updatedCount < min) {
      onCountChange(min);
      setCount(min);
      return;
    }
    if (max !== undefined && updatedCount > max) {
      onCountChange(max);
      setCount(max);
      return;
    }
    setCount(updatedCount);
    if (currentCount !== updatedCount) {
      onCountChange(updatedCount);
    }
  };

  useEffect(() => {
    setCount(currentCount);
  }, [currentCount]);

  return (
    <div className={clsx('flex gap-1 items-center', className)}>
      <Button
        onClick={() => {
          handleCountApply(count - 1);
        }}
        variant="secondary"
        icon={<IconMinus />}
        size="small"
        disabled={count === min || disabled}
      />
      <input
        disabled={disabled}
        ref={inputRef}
        type="number"
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleCountApply(count);
            inputRef.current?.blur();
          }
          if (e.key === 'Escape') {
            setCount(currentCount);
          }
        }}
        value={count}
        onChange={(e) => {
          const cleanedValue = removeLeadingZeros(e.target.value);
          setCount(Number(cleanedValue));
        }}
        onBlur={() => handleCountApply(count)}
        className="text-grey-1 text-body-md font-subtitle py-1 inline-block w-7 text-center disabled:text-grey-3 disabled:bg-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      />
      <Button
        onClick={() => handleCountApply(count + 1)}
        variant="secondary"
        icon={<IconAdd />}
        size="small"
        disabled={max ? count >= max || disabled : disabled}
      />
    </div>
  );
};
