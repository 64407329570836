import { SelectOption } from '../../components/Select/types';
import { Period } from '../../redux/api/dashboardApi/types';

export const durationFilterOptionsDictionary: Record<Period, SelectOption<Period>> = {
  day: {
    label: 'Day',
    value: 'day',
  },
  week: {
    label: 'Week',
    value: 'week',
  },
  month: {
    label: 'Month',
    value: 'month',
  },
  year: {
    label: 'Year',
    value: 'year',
  },
};

export const durationFilterOptions: SelectOption<Period>[] = Object.values(
  durationFilterOptionsDictionary,
);
