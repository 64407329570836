import { ReactNode } from 'react';
import {
  IconLogOut,
  IconMarkup,
  IconMenuExchange,
  IconPassword,
  IconPayment,
  IconProfile,
} from '../../../../assets/icons';

type MenuItem = {
  id: string;
  label: string;
  icon: ReactNode;
  url: string;
  onClick?: () => void;
};

export const adminMenuItems: MenuItem[] = [
  {
    id: 'profile',
    label: 'Profile',
    icon: <IconProfile className="text-primary-brand-dark" />,
    url: '/myProfile',
  },
  {
    id: 'changePassword',
    label: 'Change Password',
    icon: <IconPassword className="text-primary-brand-dark" />,
    url: '/change-password',
  },
  {
    id: 'logOut',
    label: 'Log out',
    icon: <IconLogOut className="text-primary-brand-dark" />,
    url: '#',
  },
];

export const retailerMenuItems: MenuItem[] = [
  {
    id: 'profile',
    label: 'Profile',
    icon: <IconProfile className="text-primary-brand-dark" />,
    url: '/myProfile',
  },
  {
    id: 'exchange',
    label: 'Exchange',
    icon: <IconMenuExchange className="text-primary-brand-dark" />,
    url: '/generatekey',
  },
  {
    id: 'markup',
    label: 'Markup',
    icon: <IconMarkup className="text-primary-brand-dark" />,
    url: '/markup',
  },
  {
    id: 'paymentMethod',
    label: 'Payment Method',
    icon: <IconPayment className="text-primary-brand-dark" />,
    url: '/payment?tabId=bank',
  },
  {
    id: 'changePassword',
    label: 'Change Password',
    icon: <IconPassword className="text-primary-brand-dark" />,
    url: '/change-password',
  },
  {
    id: 'logOut',
    label: 'Log out',
    icon: <IconLogOut className="text-primary-brand-dark" />,
    url: '#',
  },
];

export const distributorMenuItems: MenuItem[] = [
  {
    id: 'profile',
    label: 'Profile',
    icon: <IconProfile className="text-primary-brand-dark" />,
    url: '/myProfile',
  },
  {
    id: 'paymentMethod',
    label: 'Payment Method',
    icon: <IconPayment className="text-primary-brand-dark" />,
    url: '/payment?tabId=bank',
  },
  {
    id: 'changePassword',
    label: 'Change Password',
    icon: <IconPassword />,
    url: '/change-password',
  },
  {
    id: 'logOut',
    label: 'Log out',
    icon: <IconLogOut className="text-primary-brand-dark" />,
    url: '#',
  },
];
