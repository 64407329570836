import { HeaderMenu } from './headerMenu';
import { RefObject } from 'react';

type HeaderProps = {
  portalContainerRef: RefObject<HTMLDivElement>;
};

export const Header = ({ portalContainerRef }: HeaderProps) => {
  return (
    <div className="flex items-center justify-between mb-4 bg-white lg:px-[24px] px-[16px] py-[10px] rounded-2xl">
      <div className="flex flex-1 pr-8">
        <div ref={portalContainerRef} className="flex w-full" />
      </div>
      <div className="flex items-center md:mt-0">
        <HeaderMenu />
      </div>
    </div>
  );
};
