import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useRef } from 'react';
import { IconChevronDown } from '../../../../assets/icons';
import { Popover } from 'react-aria-components';
import { useHeaderMenu } from './useHeaderMenu';
import { useGetAuthUser } from '../../../../hooks';
import { ADMIN } from '../../../../constants';

export const HeaderMenu = () => {
  const { user } = useGetAuthUser();
  const { name, menuItems, isOpen, setIsOpen } = useHeaderMenu();
  const triggerRef = useRef(null);

  return (
    <>
      <div
        role="button"
        className={clsx(
          'py-2.5 px-3 flex items-center cursor-pointer outline-none transition-all duration-300 h-10',
        )}
        onClick={() => setIsOpen(true)}
        ref={triggerRef}
      >
        <div className="profile flex items-center justify-between md:gap-2 text-grey-1 hover:text-primary-brand-dark">
          <div
            className={clsx(
              'w-10 h-10 rounded-full flex items-center justify-center overflow-hidden',
              {
                'border-primary-brand': user?.image,
                'bg-primary-brand text-white text-headline-md font-headline': !user?.image,
              },
            )}
          >
            {user?.image ? (
              <img
                src={user.image}
                alt={'Default image'}
                className="w-full h-full object-contain"
              />
            ) : (
              name?.charAt(0)?.toUpperCase()
            )}
          </div>
          {user?.userType !== ADMIN && <div className="md:flex hidden">{name}</div>}
          <IconChevronDown
            className={clsx('w-4 h-4 transition-transform', {
              'transform rotate-180': isOpen,
              'transform rotate-0': !isOpen,
            })}
          />
        </div>
      </div>
      <Popover
        placement="bottom right"
        offset={8}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <div className="flex flex-col min-w-[180px] bg-white shadow-1 rounded-2.5 outline-none px-3">
          {menuItems.map((option) => (
            <div key={option.id} className="flex items-center h-10">
              <Link
                to={option.url}
                onClick={() => (option.onClick ? option.onClick() : setIsOpen(false))}
                className="flex gap-2 pl-1 text-body-md font-subtitle text-grey-1 hover:text-primary-brand-dark items-center transition-all duration-300"
              >
                {option.icon}
                {option.label}
              </Link>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};
