import { DiscountResponse, Product } from '../../../redux/api/retailerOrderListApi/types';
import { useDiscountVariants } from '../../../pages/buyProducts/useDiscountVariants';
import Skeleton from 'react-loading-skeleton';
import { useCart } from '../../../layout/cart/useCart';
import { Price } from '../index';
import { DiscountsTooltipLayout } from './DiscountsTooltipLayout';

type Props = {
  product: Product;
  productsDiscounts: Record<string, DiscountResponse>;
  onClose: () => void;
};
export const BottleDiscountTooltipContent = ({ product, productsDiscounts, onClose }: Props) => {
  const { discountVariants, isLoading } = useDiscountVariants({
    productsDiscounts,
    product,
    discountType: 'byUnit',
  });
  const { changeProductQuantity, getProductQuantityInCart } = useCart();

  const handleQuantityChange = (quantity: number) => {
    changeProductQuantity(product, getProductQuantityInCart(product) + quantity);
    onClose();
  };

  return (
    <DiscountsTooltipLayout
      onCloseClick={onClose}
      discountVariants={discountVariants}
      buttonPostfix="btl"
      product={product}
      onAddClick={handleQuantityChange}
      isByUnits
    >
      {discountVariants.map(({ quantity, discountedUnitPrice, grossUnitPrice }) => (
        <div key={`${product.productId}_${quantity}`}>
          If you add{' '}
          <span className="text-primary-brand">
            {quantity} bottle{quantity > 1 ? 's' : ''}
          </span>{' '}
          of this wine to your cart, the price per bottle will be{' '}
          <div className="inline-flex">
            {isLoading ? (
              <Skeleton className="rounded-2.5 bg-grey-3 !w-[32px]" />
            ) : (
              <Price value={discountedUnitPrice} />
            )}
          </div>{' '}
          instead of <Price value={grossUnitPrice} />.
        </div>
      ))}
    </DiscountsTooltipLayout>
  );
};
