import type { IconProps } from './types';

export const IconInfoSmall = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 7.14285C1.25 3.88832 3.88832 1.25 7.14285 1.25H8.85713C12.1117 1.25 14.75 3.88832 14.75 7.14285V8.85713C14.75 12.1117 12.1117 14.75 8.85713 14.75H7.14285C3.88832 14.75 1.25 12.1117 1.25 8.85713V7.14285ZM7.14285 2.75C4.71674 2.75 2.75 4.71674 2.75 7.14285V8.85713C2.75 11.2832 4.71674 13.25 7.14285 13.25H8.85713C11.2832 13.25 13.25 11.2832 13.25 8.85713V7.14285C13.25 4.71674 11.2832 2.75 8.85713 2.75H7.14285ZM8 3.82144C8.41421 3.82144 8.75 4.15723 8.75 4.57144V4.91431C8.75 5.32853 8.41421 5.66431 8 5.66431C7.58578 5.66431 7.25 5.32853 7.25 4.91431V4.57144C7.25 4.15723 7.58578 3.82144 8 3.82144ZM6.39287 7.14287C6.39287 6.72866 6.72866 6.39287 7.14287 6.39287H8.00001C8.41422 6.39287 8.75001 6.72866 8.75001 7.14287V10.6786H8.85714C9.27135 10.6786 9.60714 11.0144 9.60714 11.4286C9.60714 11.8428 9.27135 12.1786 8.85714 12.1786H7.14287C6.72866 12.1786 6.39287 11.8428 6.39287 11.4286C6.39287 11.0144 6.72866 10.6786 7.14287 10.6786H7.25001V7.89287H7.14287C6.72866 7.89287 6.39287 7.55708 6.39287 7.14287Z"
        fill="currentColor"
      />
    </svg>
  );
};
