import type { IconProps } from '../types';

export const IconPassword = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4 9.6V8C16.4 6.93913 15.9786 5.92172 15.2284 5.17157C14.4783 4.42143 13.4609 4 12.4 4C11.3391 4 10.3217 4.42143 9.57157 5.17157C8.82143 5.92172 8.4 6.93913 8.4 8V9.6C7.76348 9.6 7.15303 9.85286 6.70294 10.3029C6.25286 10.753 6 11.3635 6 12V17.6C6 18.2365 6.25286 18.847 6.70294 19.2971C7.15303 19.7471 7.76348 20 8.4 20H16.4C17.0365 20 17.647 19.7471 18.0971 19.2971C18.5471 18.847 18.8 18.2365 18.8 17.6V12C18.8 11.3635 18.5471 10.753 18.0971 10.3029C17.647 9.85286 17.0365 9.6 16.4 9.6ZM10 8C10 7.36348 10.2529 6.75303 10.7029 6.30294C11.153 5.85286 11.7635 5.6 12.4 5.6C13.0365 5.6 13.647 5.85286 14.0971 6.30294C14.5471 6.75303 14.8 7.36348 14.8 8V9.6H10V8ZM17.2 17.6C17.2 17.8122 17.1157 18.0157 16.9657 18.1657C16.8157 18.3157 16.6122 18.4 16.4 18.4H8.4C8.18783 18.4 7.98434 18.3157 7.83431 18.1657C7.68429 18.0157 7.6 17.8122 7.6 17.6V12C7.6 11.7878 7.68429 11.5843 7.83431 11.4343C7.98434 11.2843 8.18783 11.2 8.4 11.2H16.4C16.6122 11.2 16.8157 11.2843 16.9657 11.4343C17.1157 11.5843 17.2 11.7878 17.2 12V17.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
