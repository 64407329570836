import { Icon } from '@iconify/react';
import React from 'react';
import { MetricsData } from '../../../redux/api/dashboardApi/types';

type Props = {
  isLoading: boolean;
  data: MetricsData | undefined;
};
export const RetailerSales = ({ isLoading, data }: Props) => {
  return (
    <div className="grid lg:grid-cols-2 gap-4">
      <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
        <div className="flex justify-center items-center w-22 h-22 bg-amber-100 rounded-full">
          <Icon
            icon="material-symbols:calendar-month-rounded"
            className="text-warning"
            width="32"
            height="32"
          />
        </div>
        <div>
          <p className="font-medium mb-1.5">YTD Sales</p>
          <p className="font-semibold flex gap-2 mb-1.5 text-lg items-center flex-wrap">
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>{data?.total?.totalPrice}</>
            )}
          </p>
          <span>
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>{data?.total?.quantity} Bottles </>
            )}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
        <div className="flex justify-center items-center w-22 h-22 bg-indigo-200 rounded-full">
          <Icon icon="bi:calendar2-week-fill" className="text-indigo-800" width="24" height="24" />
        </div>
        <div>
          <p className="font-medium mb-1.5">This Week Sales</p>
          <p className="font-semibold flex mb-1.5 text-lg items-center flex-wrap">
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>
                {data?.thisWeek?.totalPrice}

                {data?.thisWeek?.isGain === 0 && data?.thisWeek?.percentage === '0.00' ? (
                  <Icon
                    icon="ion:arrow-up"
                    className="ml-1.5 text-gray-500"
                    width="22"
                    height="22"
                  />
                ) : data?.thisWeek?.isGain === 0 ? (
                  <Icon
                    icon="ion:arrow-down"
                    className="text-danger ml-1.5"
                    width="22"
                    height="22"
                  />
                ) : (
                  <Icon
                    icon="ion:arrow-up"
                    className="text-green-900 ml-1.5"
                    width="22"
                    height="22"
                  />
                )}

                <span
                  className={
                    data?.thisWeek?.isGain === 0 && data?.thisWeek?.percentage === '0.00'
                      ? 'text-gray font-normal text-sm'
                      : data?.thisWeek?.isGain === 0
                        ? 'text-danger font-normal text-sm'
                        : 'text-green-900 font-normal text-sm'
                  }
                >
                  {data?.thisWeek?.percentage}%
                </span>
              </>
            )}
          </p>
          <span>
            {' '}
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>{data?.thisWeek?.quantity} Bottles </>
            )}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
        <div className="flex justify-center items-center w-22 h-22 bg-green-400 rounded-full">
          <Icon icon="bi:calendar2-week-fill" className="text-success" width="24" height="24" />
        </div>
        <div>
          <p className="font-medium mb-1.5">Today Sales</p>
          <p className="font-semibold flex mb-1.5 text-lg items-center flex-wrap">
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>
                {data?.today?.totalPrice}
                {data?.today?.isGain === 0 && data?.today?.percentage === '0.00' ? (
                  <Icon
                    icon="ion:arrow-up"
                    className="ml-1.5 text-gray-500"
                    width="22"
                    height="22"
                  />
                ) : data?.today?.isGain === 0 ? (
                  <Icon
                    icon="ion:arrow-down"
                    className="text-danger ml-1.5"
                    width="22"
                    height="22"
                  />
                ) : (
                  <Icon
                    icon="ion:arrow-up"
                    className="text-green-900 ml-1.5"
                    width="22"
                    height="22"
                  />
                )}

                <span
                  className={
                    data?.today?.isGain === 0 && data?.today?.percentage === '0.00'
                      ? 'text-gray font-normal text-sm'
                      : data?.today?.isGain === 0
                        ? 'text-danger font-normal text-sm'
                        : 'text-green-900 font-normal text-sm'
                  }
                >
                  {data?.today?.percentage}%
                </span>
              </>
            )}
          </p>
          <span>
            {' '}
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>{data?.today?.quantity} Bottles</>
            )}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
        <div className="flex justify-center items-center w-22 h-22 bg-red-200 rounded-full">
          <Icon icon="bi:calendar2-week-fill" className="text-danger" width="24" height="24" />
        </div>
        <div>
          <p className="font-medium mb-1.5">This Month Sales</p>
          <p className="font-semibold flex mb-1.5 text-lg items-center flex-wrap">
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>
                {data?.thisMonth?.totalPrice}

                {data?.thisMonth?.isGain === 0 && data?.thisMonth?.percentage === '0.00' ? (
                  <Icon
                    icon="ion:arrow-up"
                    className="ml-1.5 text-gray-500"
                    width="22"
                    height="22"
                  />
                ) : data?.thisMonth?.isGain === 0 ? (
                  <Icon
                    icon="ion:arrow-down"
                    className="text-danger ml-1.5"
                    width="22"
                    height="22"
                  />
                ) : (
                  <Icon
                    icon="ion:arrow-up"
                    className="text-green-900 ml-1.5"
                    width="22"
                    height="22"
                  />
                )}

                <span
                  className={
                    data?.thisMonth?.isGain === 0 && data?.thisMonth?.percentage === '0.00'
                      ? 'text-gray font-normal text-sm'
                      : data?.thisMonth?.isGain === 0
                        ? 'text-danger font-normal text-sm'
                        : 'text-green-900 font-normal text-sm'
                  }
                >
                  {data?.thisMonth?.percentage}%
                </span>
              </>
            )}
          </p>
          <span>
            {' '}
            {isLoading ? (
              <div className="animate-pulse flex space-x-2 items-center">
                <div className="h-2 bg-slate-100 rounded w-16"></div>
              </div>
            ) : (
              <>{data?.thisMonth?.quantity} Bottles </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
