import { Button } from '../../index';
import { IconClose, IconInfo } from '../../../assets/icons';
import type { DiscountVariants, Product } from '../../../redux/api/retailerOrderListApi/types';
import { useCart } from '../../../layout/cart/useCart';

type Props = React.PropsWithChildren & {
  onCloseClick: () => void;
  discountVariants: DiscountVariants[];
  buttonPostfix: string;
  onAddClick: (quantity: number) => void;
  product: Product;
  isByUnits?: boolean;
};
export const DiscountsTooltipLayout = ({
  children,
  onCloseClick,
  discountVariants,
  buttonPostfix,
  onAddClick,
  product,
  isByUnits,
}: Props) => {
  const { getProductQuantityInCart } = useCart();
  const productQuantityInCart = getProductQuantityInCart(product);
  const filteredVariants = discountVariants.filter(({ quantity, unitsPerCase }) => {
    return (
      quantity > 0 &&
      product.unitsAvailable >=
        productQuantityInCart + (isByUnits ? quantity : quantity * unitsPerCase)
    );
  });
  return (
    <div className="flex gap-2 !w-[480px] pl-4 pt-4 pb-8 pr-10 text-grey-1 relative items-start">
      <Button
        variant="grey"
        icon={<IconClose className="w-4 h-4" />}
        onClick={onCloseClick}
        className="!absolute top-4 right-4"
        size="small"
      />
      <IconInfo className="min-w-6" />
      <div className="flex flex-col gap-2">
        <div className="text-headline-sm font-headline">Discounts Available</div>
        <div className="flex flex-col gap-5">{children}</div>
        {!!filteredVariants.length && (
          <div className="flex gap-4 py-4">
            {[...filteredVariants]
              .sort((a, b) => b.quantity - a.quantity)
              .map(({ quantity }, index) => (
                <Button
                  variant={index === 0 ? 'primary' : index === 1 ? 'secondary' : 'text'}
                  key={`action_${quantity}`}
                  onClick={() => onAddClick(quantity)}
                >
                  Add {quantity} {buttonPostfix}
                  {quantity > 1 ? 's' : ''}
                </Button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
