import type { IconProps } from '../types';

export const IconLogOut = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.75C10.3424 5.75 8.75269 6.40848 7.58058 7.58058C6.40848 8.75269 5.75 10.3424 5.75 12C5.75 13.6576 6.40848 15.2473 7.58058 16.4194C8.75269 17.5915 10.3424 18.25 12 18.25C12.4142 18.25 12.75 18.5858 12.75 19C12.75 19.4142 12.4142 19.75 12 19.75C9.94457 19.75 7.97333 18.9335 6.51992 17.4801C5.06652 16.0267 4.25 14.0554 4.25 12C4.25 9.94457 5.06652 7.97333 6.51992 6.51992C7.97333 5.06652 9.94457 4.25 12 4.25C12.4142 4.25 12.75 4.58579 12.75 5C12.75 5.41421 12.4142 5.75 12 5.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0238 9.51986C16.289 9.20165 16.7619 9.15866 17.0801 9.42383L19.4801 11.4238C19.6511 11.5663 19.75 11.7774 19.75 12C19.75 12.2226 19.6511 12.4337 19.4801 12.5762L17.0801 14.5762C16.7619 14.8413 16.289 14.7983 16.0238 14.4801C15.7587 14.1619 15.8017 13.689 16.1199 13.4238L16.9285 12.75H11C10.5858 12.75 10.25 12.4142 10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H16.9285L16.1199 10.5762C15.8017 10.311 15.7587 9.83807 16.0238 9.51986Z"
        fill="currentColor"
      />
    </svg>
  );
};
