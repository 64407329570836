import React, { lazy, Suspense, FC, useState, LazyExoticComponent } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layout/dashboard/DashboardLayout';
import { LoadingScreen } from '../components';
import AuthGuard from '../guards/AuthGuard';
import { useIsAuthenticated } from '@azure/msal-react';
import PaymentCardAdd from '../pages/payment/PaymentCardAdd';
import PaymentBankAdd from '../pages/payment/PaymentBankAdd';
import ViewPayment from '../pages/payment';
import { SidebarProps } from '../types';

const Loadable =
  (Component: LazyExoticComponent<React.FC<SidebarProps>>) => (props: SidebarProps) => {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };
const AppRoutes: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  // const stripe = useStripe()

  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard isSignedIn={isAuthenticated}>
          <DashboardLayout isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/myProfile',
          element: <MyProfile />,
        },

        {
          path: '/distributors',
          children: [
            {
              path: '/distributors/list',
              element: <DistributerList />,
            },
            {
              path: '/distributors/add',
              element: <DistributerForm />,
            },
            {
              path: '/distributors/invite',
              element: <DistributerInvite />,
            },
            {
              path: '/distributors/:tabId',
              element: <ViewDistributor />,
            },
            {
              path: '/distributors/connect',
              element: <DistributorConnect />,
            },
            {
              path: '/distributors/user/details',
              element: <DistributorUserDetails />,
            },
          ],
        },

        {
          path: '/retailers',
          children: [
            {
              path: '/retailers/list',
              element: <RetailerList />,
            },
            {
              path: '/retailers/add',
              element: <RetailerForm />,
            },
            {
              path: '/retailers/invite',
              element: <RetailerInvite />,
            },
            {
              path: '/retailers/:tabId',
              element: <ViewRetailer />,
            },
          ],
        },
        {
          path: '/exchange',
          element: <Exchange />,
        },
        {
          path: '/markup',
          element: <Markup />,
        },
        {
          path: '/generatekey',
          element: <GenerateKey />,
        },
        {
          path: '/orders/list',
          element: <RetailerOrderList />,
        },
        {
          path: '/orders-details',
          element: <RetailerOrderDetails />,
        },
        {
          path: '/orders-details/:orderId',
          element: <RetailerOrderDetails />,
        },
        {
          path: '/orders/:tabId',
          element: <ViewRetailerOrderDetails />,
        },
        {
          path: '/invoices',
          element: <PlatformFees />,
        },

        {
          path: '/cancel',
          element: <Cancel />,
        },
        {
          path: '/platformfee/details',
          element: <PlatformFeesDetails />,
        },
        {
          path: '/reports/order-summary',
          element: <MonthlyReports />,
        },
        {
          path: '/reports/retailer-report',
          element: <TopRetailerReports />,
        },
        {
          path: '/reports/purchased-products',
          element: <PurchasedProducts />,
        },
        {
          path: '/reports/monthly-report',
          element: <MonthlyReports />,
        },
        {
          path: '/reports/platform-report',
          element: <PlatformReport />,
        },
        {
          path: '/reports/revenue-report',
          element: <RevenueReport />,
        },
        {
          path: '/reports/distributor-report',
          element: <DistributorReports />,
        },
        {
          path: '/mappedDistributors',
          element: <ViewMappedDistributor />,
        },
        {
          path: '/retailers/mapped-retailer',
          element: <ViewMappedRetailer />,
        },
        {
          path: '/retailers/mapped-request',
          element: <ViewMappedRetailer />,
        },
        {
          path: '/retailers/buy-products',
          element: <BuyProducts />,
        },
        {
          path: '/retailers/cart',
          element: <Cart />,
        },
        {
          path: '/payment',
          children: [
            {
              path: '/payment',
              element: <ViewPayment />,
            },
            {
              path: '/payment/card-add',
              element: <PaymentCardAdd />,
            },
            {
              path: '/payment/bank-add',
              element: <PaymentBankAdd />,
            },
          ],
        },
      ],
    },

    { path: '/403', element: <Forbiden403 /> },
    {
      path: '/retailer-onboard',
      element: (
        <RetailerOnboarding setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} />
      ),
    },
    {
      path: '/distributor-onboard',
      element: <DistributorOnboarding />,
    },

    {
      path: '/401',
      element: <UnAuthorised401 />,
    },
    {
      path: '/passwordsetup',
      element: <NewPasswordSetup />,
    },
    {
      path: '/change-password',
      element: <ChangePassword />,
    },

    {
      path: '/agreement',
      element: <Agreement />,
    },
    {
      path: '/payment-inprogress',
      element: <Success />,
    },
    {
      path: '*',
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
};

const DistributerList = Loadable(lazy(() => import('../pages/distributers/DistributerList')));
const DistributerInvite = Loadable(lazy(() => import('../pages/distributers/DistributerInvite')));
const DistributerForm = Loadable(lazy(() => import('../pages/distributers/DistributerForm')));
const ChangePassword = Loadable(
  lazy(() => import('../pages/changePasswordSetup/ChangePasswordSetup')),
);

const RetailerList = Loadable(lazy(() => import('../pages/retailer/RetailerList')));
const RetailerForm = Loadable(lazy(() => import('../pages/retailer/RetailerForm')));

const ViewRetailer = Loadable(lazy(() => import('../pages/viewretailer/index')));

const Exchange = Loadable(lazy(() => import('../pages/exchange/Exchange')));

const Markup = Loadable(lazy(() => import('../pages/markup')));
const GenerateKey = Loadable(lazy(() => import('../pages/generateKey/GenerateKey')));

const RetailerOrderList = Loadable(lazy(() => import('../pages/retailerOrder/RetailerOrderList')));

const RetailerOrderDetails = Loadable(
  lazy(() => import('../pages/retailerOrder/RetailerOrderDetails')),
);

const PlatformFees = Loadable(lazy(() => import('../pages/PlatformFees')));

const PlatformFeesDetails = Loadable(
  lazy(() => import('../pages/viewretailer/PlatformFeesDetails')),
);

const MonthlyReports = Loadable(lazy(() => import('../pages/reports/MonthlyReports')));

const TopRetailerReports = Loadable(lazy(() => import('../pages/reports/TopRetailerReports')));

const PurchasedProducts = Loadable(lazy(() => import('../pages/reports/PurchasedProducts')));

const ViewMappedDistributor = Loadable(lazy(() => import('../pages/mappedDistributor')));

const DistributorReports = Loadable(lazy(() => import('../pages/reports/DistributorReports')));

const BuyProducts = Loadable(lazy(() => import('../pages/buyProducts')));

const Cart = Loadable(lazy(() => import('../pages/cart')));

const PlatformReport = Loadable(lazy(() => import('../pages/reports/PlatformReport')));

const DistributorUserDetails = Loadable(
  lazy(() => import('../pages/distributers/DistributorUserDetails')),
);

const RevenueReport = Loadable(lazy(() => import('../pages/reports/RevenueReport')));

const ViewMappedRetailer = Loadable(lazy(() => import('../pages/mappedRetailer')));

const DistributorConnect = Loadable(lazy(() => import('../pages/distributers/DistributorConnect')));

const ViewDistributor = Loadable(lazy(() => import('../pages/viewDistributor')));

const NewPasswordSetup = Loadable(lazy(() => import('../pages/newPasswordSetup/NewPasswordSetup')));

const Agreement = Loadable(lazy(() => import('../pages/agreement/Agreement')));

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));

const Success = Loadable(lazy(() => import('../pages/Success')));

const Cancel = Loadable(lazy(() => import('../pages/Cancel')));

const ViewRetailerOrderDetails = Loadable(lazy(() => import('../pages/viewRetailerOrderDetails')));

const MyProfile = Loadable(lazy(() => import('../pages/myProfile/MyProfile')));

const RetailerInvite = Loadable(lazy(() => import('../pages/retailer/RetailerInvite')));

const Page404 = Loadable(lazy(() => import('../pages/Page404')));

const Forbiden403 = Loadable(lazy(() => import('../pages/Forbiden403')));

const RetailerOnboarding = Loadable(lazy(() => import('../pages/retailer/RetailerOnboarding')));

const DistributorOnboarding = Loadable(
  lazy(() => import('../pages/distributers/DistributorOnboarding')),
);

const UnAuthorised401 = Loadable(lazy(() => import('../pages/UnAuthorised401')));

export default AppRoutes;
