import { createPortal } from 'react-dom';

type Props = React.PropsWithChildren & {
  node: HTMLElement | undefined | null;
};

export const Portal = ({ node, children }: Props) => {
  if (!node) {
    return null;
  }
  return createPortal(children, node);
};
