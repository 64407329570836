import { ReactNode } from 'react';
import { RETAILER } from '../../../constants';
import { CartCountIcon, LocationSelect } from '../../../components/shared';
import { Button } from '../../../components';
import { useCart } from '../../cart/useCart';
import { useNavigate } from 'react-router-dom';
import { useGetAuthUser } from '../../../hooks';

type HeaderContentLayoutProps = {
  children?: ReactNode;
  isCartDisabled?: boolean;
};

export const HeaderContent = ({ children, isCartDisabled = false }: HeaderContentLayoutProps) => {
  const { selectedLocation, updateSelectedLocation, locations, total } = useCart();
  const navigate = useNavigate();
  const { user } = useGetAuthUser();

  return (
    <div className="w-full flex justify-between items-center gap-8">
      {children}
      {user?.userType === RETAILER && (
        <div className="flex gap-8 items-center">
          <LocationSelect
            isDisabled={isCartDisabled}
            locations={locations}
            selectedLocation={selectedLocation}
            updateSelectedLocation={updateSelectedLocation}
          />
          <Button
            {...(location.pathname !== '/retailers/cart'
              ? { onClick: () => navigate('/retailers/cart') }
              : {})}
            icon={<CartCountIcon count={total?.bottlesCount ?? 0} />}
            variant="grey"
            size="small"
          >
            Cart
          </Button>
        </div>
      )}
    </div>
  );
};
